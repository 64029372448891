import styled from 'styled-components'

const PreviewWrapper = styled.div`
width: 65%;
margin: auto;
.selected-sentences{
    list-style: circle;
}
.preview-container{
    margin: 10px;
}
    
`
export default PreviewWrapper