import styled from "styled-components";

const SharedContainer = styled.section`
  display: flex;
  flex-direction: row;
  /* .dashbaord {
    padding:20px;
  } */

  .nav-page {
    width: 90vw;
    margin: 0 auto;
    padding: 20px;
  }
`;
export default SharedContainer;
